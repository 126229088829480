import { Button } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import {
  ArrowBackIos as ArrowBackIosIcon,
  RemoveShoppingCart as CannotBuyIcon,
  Send as SendIcon,
  ShoppingCart as ShoppingCartIcon,
} from '@material-ui/icons'
import {
  IAdminCustomer,
  IAdminCustomTermsRecord,
  IContractAdjustmentRequest,
  IContractCreationData,
  IContractPrintCreationRequest,
  ICustomContractCreationRequest,
  IStandardContractCreationRequest,
  IStandardV4PricingToolContractCreationRequest,
  IVehicleInfo,
  PaymentGateway,
  PriceSource,
  ProductAlongItsContracts,
  ResponseErrors,
  Vehicle,
  VehicleAlongItsContracts,
} from '@omnicar/sam-types'
import { IContractProviderInfo } from '@omnicar/sam-types/types/admin/contractProvider'
import {
  lookupUser,
  pdfDownloadUrl,
  postContractAdjust,
  postContractOffer,
  postContractPrint,
  postCustomTerms,
} from 'api/api'
import classNames from 'classnames'
import ContractFlowCustomer from 'components/admin/Contract/Flow/Customer'
import ContractFlowCustomTerms from 'components/admin/Contract/Flow/CustomTerms'
import ContractFlowPaymentGateway from 'components/admin/Contract/Flow/PaymentGateway'
import ContractFlowReference from 'components/admin/Contract/Flow/Reference'
import ContractFlowSendDialog from 'components/admin/Contract/Flow/SendDialog'
import ContractFlowSummary from 'components/admin/Contract/Flow/Summary'
import WarningMessage from 'components/admin/Contract/Flow/WarningMessage'
import ConfirmDialog from 'components/ConfirmDialog'
import LoadingOverlay from 'components/LoadingOverlay'
import {
  LayoutActions,
  LayoutActionsLeft,
  LayoutActionsRight,
  LayoutBlock,
  LayoutColumn,
  LayoutRow,
} from 'components/Mui/Layout'
import React, { ChangeEvent } from 'react'
import { contractDetailsPath } from 'routes/paths'
import { AppContext } from 'store/appContext'
import { theme as customTheme } from 'theme'
import { t } from 'translations/translationFunctions'
import { ContractFlowActivePanel, IContractFlow } from 'types/contractFlow'
import { trackEvent } from 'utils/analytics'
import browserHistory from 'utils/history'
import notify from 'utils/notify/notify'
import { AllTermsCheckedOnContract } from 'utils/sessionStorage'
import { tDot } from 'utils/string'
import { vehicleToVehicleInfo } from 'utils/vehicle'
import ContractFlowPaymentDialog from './PaymentDialog'

interface IOwnProps {
  activePanel: ContractFlowActivePanel
  contract: IContractFlow
  creationData: IContractCreationData | undefined
  freeContract: boolean
  providerInfo: IContractProviderInfo | undefined
  prettyIdentifier: string | undefined
  contractFlowReset: () => void
  onActivePanelChange: (activePanel: ContractFlowActivePanel) => void
  onBack: () => void
  onChange: (values: Partial<IContractFlow>) => void
  onFinalStep: (isFinal: boolean) => void
  onFreeContract: () => void
  onResetContract: () => void
  onContractUpdate: () => void
  onCustomerChange: (value: IAdminCustomer, valid: boolean) => void
  onCustomerLockedChange: (locked: boolean) => void
  valid: { customer: boolean }
  contractUpdates: number
  vehicleUpdates: number
  customerUpdates: number
  warrantyCreated: boolean
}

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  finalizationError: boolean
  finalizationErrorMessage: ResponseErrors | ''
  finalizationTransaction: boolean
  serviceContractIdNum: number | undefined
  prettyIdentifier: string
  sendDialog: boolean
  purchaseDialog: boolean
  showConfirmSendContractOffer: boolean
  showConfirmBuyNowV4: boolean
  showConfirmBuyNowStripe: boolean
  isPaid: boolean
  isCreating: boolean
  isLoading: boolean
  activateContractFail: boolean
  eula1: boolean
  eula2: boolean
  eulaOptions: Map<number, boolean>
  isAllTermsChecked: boolean
  contractPaymentGateway: PaymentGateway
}

const styles = ({ spacing, typography }: Theme) =>
  createStyles({
    root: {
      padding: `0 0 0 ${spacing(1)}`,
    },
    button: {
      marginLeft: spacing(1),
    },
    buttonIcon: {
      marginRight: spacing(1),
      fontSize: 16,
    },
    checkbox: {
      marginRight: spacing(1),
      fontSize: 16,
    },
    section: {
      padding: `${spacing(2)}px ${spacing(1)}px`,
      '&:nth-child(even)': {
        backgroundColor: customTheme.palette.background.lighter,
      },
      '&:first-child': {
        paddingTop: spacing(2),
      },
      '&:last-child': {
        paddingBottom: spacing(2),
      },
    },
    addSpaceAfter: {
      marginRight: `0.3em`,
    },
    sectionItem: {
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'space-between',
      marginRight: spacing(1),
      fontSize: 14,
    },
    buyNowButtonsDiv: {
      paddingTop: -4 * spacing(1),
      textAlign: 'center',
      paddingBotton: 4 * spacing(1),
    },
    sameWidth: {
      width: 38 * spacing(1),
    },
    buttonColorBuyDealerPaid: {
      color: '#111',
      backgroundColor: '#5bb5c2',
    },
    cursorWait: {
      cursor: 'wait',
    },
    errorDiv: {
      fontSize: 16,
      backgroundColor: 'orange',
    },
    pullRight: {
      textAlign: 'right',
    },
  })

class ContractFlowPagePayment extends React.Component<TProps, IState> {
  public constructor(props: TProps) {
    super(props)

    const eulaOptions: Map<number, boolean> = new Map<number, boolean>()
    this.props.contract.options.forEach((option) => {
      if (option.termsOfService) {
        eulaOptions.set(option.id, false)
      }
    })

    // Check that all payment gateways are valid.
    const providerPaymentGateways = this.props.creationData ? this.props.creationData.payment.paymentGateways : []
    providerPaymentGateways.forEach((item: string) => {
      if (!['Stripe', 'B2B', 'V4', 'NONE', 'Mock'].includes(item)) {
        notify.warning({ message: 'Warning: Unknown payment gateway: ' + item })
      }
    })

    this.state = {
      finalizationError: false,
      finalizationErrorMessage: '',
      finalizationTransaction: false,
      prettyIdentifier: '',
      serviceContractIdNum: undefined,
      sendDialog: false,
      purchaseDialog: false,
      showConfirmSendContractOffer: false,
      showConfirmBuyNowV4: false,
      showConfirmBuyNowStripe: false,
      isPaid: false,
      isCreating: false,
      isLoading: false,
      activateContractFail: false,
      eula1: false,
      eula2: false,
      eulaOptions,
      isAllTermsChecked: true,
      contractPaymentGateway: 'Stripe',
    }
  }

  public componentDidUpdate(oldProps: Readonly<TProps>): void {
    const newProps: TProps = this.props

    const newPaymentGateway = newProps.contract.paymentGateway
    const oldPaymentGateway = oldProps.contract.paymentGateway

    if (newPaymentGateway !== oldPaymentGateway) {
      this.setState({ contractPaymentGateway: newPaymentGateway })
    }
  }

  public render() {
    const { valid, activePanel, contract, creationData, freeContract, providerInfo } = this.props
    const { eulaOptions, showConfirmSendContractOffer, isLoading } = this.state

    let eulaOptionsAgreed: boolean = true
    eulaOptions.forEach((value) => (eulaOptionsAgreed = eulaOptionsAgreed && value))

    const providerPaymentGateways = creationData ? creationData.payment.paymentGateways : []

    // Hides the "Payment Methods" card when isUsingV4PricingTool.
    const isShowCardPaymentGateway: boolean =
      !providerInfo!.isUsingV4PricingTool && !providerInfo!.isUseV4PTOnlyForSigning

    return (
      <>
        <LoadingOverlay open={isLoading} />
        <LayoutRow columns={2}>
          <LayoutColumn>
            <ContractFlowCustomer
              active={activePanel}
              activeEnum={ContractFlowActivePanel.customer}
              flow={contract.flowType}
              locked={contract.state.customerSearched}
              onChange={this.props.onCustomerChange}
              onLockedChange={this.props.onCustomerLockedChange}
              value={contract.customer}
              valid={valid.customer}
              freeContract={freeContract}
            />
            {isShowCardPaymentGateway &&
              creationData &&
              providerPaymentGateways.filter((g: PaymentGateway) => g !== 'NONE').length > 1 &&
              contract.paymentGateway !== 'NONE' && (
                <ContractFlowPaymentGateway
                  freeContract={freeContract}
                  active={activePanel >= ContractFlowActivePanel.paymentGateway}
                  value={contract.paymentGateway}
                  onChange={this.handlePaymentGatewayChange}
                  paymentGateways={providerPaymentGateways.filter((g: PaymentGateway) => g !== 'NONE')}
                  flow={contract.flowType}
                />
              )}
            <ContractFlowReference
              active={activePanel > ContractFlowActivePanel.customer}
              onChange={this.handleReferenceChange}
              value={contract.reference}
              freeContract={freeContract}
            />
            <ContractFlowCustomTerms
              active={activePanel > ContractFlowActivePanel.customer}
              onBlur={this.handleCustomTermsChange}
              value={contract.customTerms}
              freeContract={freeContract}
            />
          </LayoutColumn>

          <LayoutColumn>
            <LayoutBlock dense={true}>
              <ContractFlowSummary
                freeContract={freeContract}
                contract={contract}
                creationData={creationData!}
                providerInfo={providerInfo}
                printDisabled={activePanel < ContractFlowActivePanel.customer}
                onPrint={this.handlePrintOffer}
                contractUpdates={this.props.contractUpdates}
              />
            </LayoutBlock>

            {this.renderPurchaseButtons()}
          </LayoutColumn>
        </LayoutRow>
        <ContractFlowSendDialog
          open={this.state.sendDialog}
          error={this.state.finalizationError}
          errorMessage={this.state.finalizationErrorMessage}
          onClose={this.handleDialogClose}
          onToOffer={this.handleToOffer}
          onCreateNew={this.sendCreateNew}
          contract={contract}
          prettyIdentifier={this.state.prettyIdentifier}
          sending={this.state.finalizationTransaction}
        />
        {showConfirmSendContractOffer && (
          <ConfirmDialog
            open={true}
            titleTrl="Send Offer?"
            onConfirm={this.handleSendContractOffer}
            onCancel={this.handleCloseShowConfirmSend}
            contentTrlText={t('Send offer to:') + ' ' + contract.customer.email + '\n(' + contract.customer.name + ')'}
            captionTrlConfirmButton={
              contract.flowType === 'CREATE' ? t('Yes, send contract offer') : t('Yes, send contract adjustment offer')
            }
            captionTrlCancelButton={t('No, Cancel')}
          />
        )}
        {this.state.purchaseDialog && (
          <AppContext.Consumer>
            {({ locale }) => (
              <ContractFlowPaymentDialog
                onBack={this.handleDialogClose}
                contractPrettyIdentifier={this.state.prettyIdentifier}
                onPreviewContract={this.handleToOffer}
                locale={locale}
              />
            )}
          </AppContext.Consumer>
        )}
      </>
    )
  }

  public renderPurchaseButtons() {
    const { providerInfo } = this.props
    const isProviderUsingV4PricingTool = providerInfo!.isUsingV4PricingTool || providerInfo!.isUseV4PTOnlyForSigning

    if (!isProviderUsingV4PricingTool) {
      return this.renderPurchaseButtonsOriginal()
    } else {
      return this.renderPurchaseButtonsV4PT()
    }
  }

  /**
   * Render Original (non V4PricingTool) purchase buttons.
   */
  public renderPurchaseButtonsOriginal() {
    const { activePanel, classes, contract, creationData, onBack, providerInfo } = this.props
    const { eula1, eula2, eulaOptions, isAllTermsChecked } = this.state

    const termsOfTrade = creationData ? creationData.termsOfTradeRef : ''

    let eulaOptionsAgreed: boolean = true
    eulaOptions.forEach((value) => (eulaOptionsAgreed = eulaOptionsAgreed && value))

    const eulaIsAgreed = eulaOptionsAgreed && (termsOfTrade ? eula1 && eula2 : eula1)
    const allowSubmit = eulaIsAgreed || isAllTermsChecked
    const isBuyNowButtonVisible = !contract.state.licenseSkip && contract.paymentGateway === 'Stripe'
    const sendButtonVisible = !contract.state.licenseSkip

    // The NONE gateway is not shown in the ContractFlowPaymentGateway component.
    const warningMessage = this.getWarrantyErrorMessage()
    const disableActions = warningMessage ? true : activePanel < ContractFlowActivePanel.paymentGateway

    const isProviderUsingV4PricingTool = providerInfo!.isUsingV4PricingTool

    if (isProviderUsingV4PricingTool) {
      return <div className={classes.errorDiv}>--Non Original buttons. Please contract support--</div>
    } else {
      return (
        <>
          <LayoutActions>
            <LayoutActionsLeft>
              <Button onClick={onBack} size="small" variant="outlined">
                <ArrowBackIosIcon className={classes.buttonIcon} />
                {t('Back')}
              </Button>
            </LayoutActionsLeft>
            <LayoutActionsRight>
              {isBuyNowButtonVisible && (
                <React.Fragment>
                  <Button
                    className={classes.button}
                    size="small"
                    color="secondary"
                    variant="contained"
                    disabled={disableActions || !allowSubmit}
                    onClick={() => {
                      this.setState({ isLoading: true })
                      this.closeAllConfirmDialogs({ isLoading: true }, () => {
                        // (!) Important, close all confirm dialogs before continuing to purchasing contract
                        //     (otherwise user may click multiple times on confirm and send multiple
                        //     requests to server).
                        this.handlePurchaseContract(contract.paymentGateway)
                      })
                    }}
                    data-e2e={'ContractFlowPagePayment__button-purchaseOffer'}
                  >
                    <ShoppingCartIcon className={classes.buttonIcon} />
                    {t('Buy now')}
                  </Button>
                </React.Fragment>
              )}
              {sendButtonVisible && (
                <Button
                  className={classes.button}
                  size="small"
                  color="primary"
                  variant="contained"
                  disabled={disableActions}
                  onClick={this.showConfirmSendContractOffer}
                  data-e2e={'ContractFlowPagePayment__button-sendOffer'}
                >
                  <SendIcon className={classes.buttonIcon} />
                  {contract.flowType === 'CREATE' ? t('Send Offer') : t('Send Adjustment Offer')}
                </Button>
              )}
            </LayoutActionsRight>
          </LayoutActions>
        </>
      )
    }
  }

  /**
   * Render V4PricingTool purchase buttons.
   */
  public renderPurchaseButtonsV4PT() {
    const { activePanel, classes, contract, creationData, onBack, providerInfo } = this.props
    const {
      eula1,
      eula2,
      eulaOptions,
      isAllTermsChecked,
      isCreating,
      showConfirmBuyNowV4,
      showConfirmBuyNowStripe,
    } = this.state

    const isProviderUsingV4PricingTool = providerInfo!.isUsingV4PricingTool || providerInfo!.isUseV4PTOnlyForSigning

    const termsOfTrade = creationData ? creationData.termsOfTradeRef : ''

    let eulaOptionsAgreed: boolean = true
    eulaOptions.forEach((value) => (eulaOptionsAgreed = eulaOptionsAgreed && value))

    const eulaIsAgreed = eulaOptionsAgreed && (termsOfTrade ? eula1 && eula2 : eula1)
    const allowSubmit = eulaIsAgreed || isAllTermsChecked

    // The NONE gateway is not shown in the ContractFlowPaymentGateway component.
    const providerPaymentGateways = creationData ? creationData.payment.paymentGateways : []

    // Note: Only contracts that has 'Subscription' payment can send offers (which
    // in turn can be opened by end-customer for payment with credit card.)
    let isSendOfferButtonVisible =
      !contract.state.licenseSkip && contract.v4SupportedPaymentTypes.includes('CustomerSubscription')

    // One-time Dealer Payment.
    const isBuyNowV4ButtonVisible =
      !contract.state.licenseSkip &&
      providerPaymentGateways.includes('V4') &&
      isProviderUsingV4PricingTool &&
      contract.v4SupportedPaymentTypes.includes('DealerPaid')

    // Customer Payment Card / Customer Monthly Subscription.
    const isBuyNowStripeButtonVisible =
      !contract.state.licenseSkip &&
      providerPaymentGateways.includes('Stripe') &&
      contract.v4SupportedPaymentTypes.includes('CustomerSubscription')

    const warningMessage = this.getWarrantyErrorMessage()
    const disableActions = warningMessage ? true : activePanel < ContractFlowActivePanel.paymentGateway

    if (!isProviderUsingV4PricingTool) {
      return <div className={classes.errorDiv}>--Non V4PricingTool buttons. Please contract support--</div>
    } else {
      // Error detection.
      if (!isBuyNowV4ButtonVisible && !isBuyNowStripeButtonVisible) {
        return (
          <div className={classNames(classes.errorDiv, classes.pullRight)}>
            <CannotBuyIcon className={classes.buttonIcon} />
            --No allowed Payment Gateway set up on this Provider, matches any of the payment types for this
            contract/template. Please contract support--
          </div>
        )
      }

      return (
        <>
          <div className={classNames(classes.buyNowButtonsDiv, isCreating && classes.cursorWait)}>
            <br />
            {isBuyNowV4ButtonVisible && (
              <LayoutActions>
                <LayoutActionsRight>
                  <div>
                    <span title={t('Buy one-time dealer payment')}>
                      <Button
                        className={classNames(
                          classes.button,
                          classes.sameWidth,
                          classes.buttonColorBuyDealerPaid,
                          isCreating && classes.cursorWait,
                        )}
                        size="small"
                        color="secondary"
                        variant="contained"
                        disabled={disableActions || !allowSubmit}
                        onClick={() => {
                          this.setState({ showConfirmBuyNowV4: true })
                        }}
                        data-e2e={'ContractFlowPagePayment__button-OnetimeDealerPaid'}
                      >
                        <ShoppingCartIcon className={classes.buttonIcon} />
                        {t('One-time Dealer Payment')}
                      </Button>
                      <br />
                    </span>
                  </div>
                </LayoutActionsRight>
              </LayoutActions>
            )}
            {isBuyNowStripeButtonVisible && (
              <LayoutActions>
                <LayoutActionsRight>
                  <div>
                    <br />
                    <span title={t('Buy customer monthly subscription')}>
                      <Button
                        className={classNames(classes.button, classes.sameWidth, isCreating && classes.cursorWait)}
                        size="small"
                        color="secondary"
                        variant="contained"
                        disabled={disableActions || !allowSubmit}
                        onClick={() => {
                          this.setState({ showConfirmBuyNowStripe: true })
                        }}
                        data-e2e={'ContractFlowPagePayment__button-purchaseOffer'}
                      >
                        <ShoppingCartIcon className={classes.buttonIcon} />
                        {t('Customer Monthly Subscription')}
                      </Button>
                    </span>
                  </div>
                </LayoutActionsRight>
              </LayoutActions>
            )}
          </div>

          <WarningMessage message={warningMessage} />

          <LayoutActions>
            <LayoutActionsLeft>
              <span title={t('Back to previous page')}>
                <Button onClick={onBack} size="small" variant="outlined">
                  <ArrowBackIosIcon className={classes.buttonIcon} />
                  {t('Back')}
                </Button>
              </span>
            </LayoutActionsLeft>
            <LayoutActionsRight>
              {isSendOfferButtonVisible && (
                <span title={t('Send offer')}>
                  <Button
                    className={classNames(classes.button, classes.sameWidth)}
                    size="small"
                    color="primary"
                    variant="contained"
                    disabled={disableActions}
                    onClick={this.showConfirmSendContractOffer}
                    data-e2e={'ContractFlowPagePayment__button-sendOffer'}
                  >
                    <SendIcon className={classes.buttonIcon} />
                    {contract.flowType === 'CREATE' ? t('Send Offer to Customer') : t('Send Adjustment Offer')}
                  </Button>
                </span>
              )}
            </LayoutActionsRight>
          </LayoutActions>

          {showConfirmBuyNowV4 && (
            <ConfirmDialog
              open={true}
              titleTrl={t('Buy one-time dealer payment')}
              contentTrlText={t('Buy this contract now as a dealer paid contract, are you sure?')}
              onConfirm={() => {
                this.setState({ isLoading: true })
                this.closeAllConfirmDialogs({ isLoading: true }, () => {
                  // (!) Important, close all confirm dialogs before continuing to purchasing contract
                  //     (otherwise user may click multiple times on confirm and send multiple
                  //     requests to server).
                  this.handlePurchaseContract('V4')
                })
              }}
              onCancel={() => {
                this.closeAllConfirmDialogs()
              }}
              captionTrlConfirmButton={t('Yes, buy now')}
              captionTrlCancelButton={t('No, Cancel')}
            />
          )}
          {showConfirmBuyNowStripe && (
            <ConfirmDialog
              open={true}
              titleTrl={t('Buy customer monthly subscription')}
              contentTrlText={t('Buy this contract now as a customer monthly subscription contract, are you sure?')}
              onConfirm={() => {
                this.setState({ isLoading: true })
                this.closeAllConfirmDialogs({ isLoading: true }, () => {
                  // (!) Important, close all confirm dialogs before continuing to purchasing contract
                  //     (otherwise user may click multiple times on confirm and send multiple
                  //     requests to server).
                  this.handlePurchaseContract('Stripe')
                })
              }}
              onCancel={() => {
                this.closeAllConfirmDialogs()
              }}
              captionTrlConfirmButton={t('Yes, buy now')}
              captionTrlCancelButton={t('No, Cancel')}
            />
          )}
        </>
      )
    }
  }

  /**
   *
   * @param state Optional extra state(s) to set along with closing all confirm dialogs.
   * @param callback Optional function to call, when and only, after all state(s) has
   *        been done and rendered.
   */
  private closeAllConfirmDialogs = (state?: object, callback?: () => void): void => {
    this.setState(
      {
        ...state,
        showConfirmSendContractOffer: false,
        showConfirmBuyNowV4: false,
        showConfirmBuyNowStripe: false,
      },
      callback,
    )
  }

  private getWarrantyErrorMessage = (): string => {
    const CK_WARRANTY_ALREADY_CREATED =
      'You can not create a contract type or option with warranty because you have already created a warranty for this vehicle'

    if (this.props.warrantyCreated && this.contractHasWarranty()) {
      return tDot(CK_WARRANTY_ALREADY_CREATED)
    }
    return ''
  }

  private contractHasWarranty = (): boolean => {
    const { contract } = this.props
    const { template } = contract
    if (template && template.properties.some((p) => (p.warranty ? true : false))) {
      return true
    }
    return contract.options.some((o) => (o.warranty ? true : false))
  }

  private sendCreateNew = () => {
    this.props.contractFlowReset()

    this.setState({
      finalizationError: false,
      finalizationErrorMessage: '',
      finalizationTransaction: false,
      prettyIdentifier: '',
      isPaid: false,
      isCreating: false,
      isLoading: false,
      activateContractFail: false,
    })
  }

  private createAdjustOfferRequest = (): IContractAdjustmentRequest => {
    const { contract, providerInfo } = this.props
    const { value, payments } = contract

    const parsedStartDateISOString = !contract.contractStartDateISO
      ? ''
      : new Date(contract.contractStartDateISO).toISOString() // NOTE: Important to call toISOString() on a created Date object.

    return {
      contractProviderId: providerInfo!.providerId,
      contractTemplateId: contract.template!.id,
      serviceVariantId: contract.template!.serviceVariantId,
      serviceVariantName: contract.template!.serviceVariantName,
      duration: contract.duration.value,
      mileage: contract.mileage.value,
      startDateISOString: parsedStartDateISOString,
      value: value ? value.value : value,
      optionIds: contract.options.map((option) => option.id),
      providerPayments: payments.providerPayments,
      providerShare: payments.providerShare.priceInclVat,
      startMileage: contract.startMileage,
      startValue: contract.startValue,
      valueType: contract.valueType,
      startValueType: contract.startValueType,
      type: contract.contractType,
      amountPerPayment: payments.customAmountPrPayment,
      contractProductType: null,
    }
  }

  private createPrintOfferRequest = () => {
    const { contract, freeContract, prettyIdentifier, providerInfo } = this.props
    const { value } = contract

    const vehicleForRequest: VehicleAlongItsContracts = { ...(contract.product! as VehicleAlongItsContracts) }
    vehicleForRequest.contracts = { contracts: [], durations: [], mileageDurationsMap: null }
    const parsedStartDateISOString = !contract.contractStartDateISO
      ? ''
      : new Date(contract.contractStartDateISO).toISOString() // NOTE: Important to call toISOString() on a created Date object.

    const request: IContractPrintCreationRequest = {
      type: freeContract ? 'CUSTOM' : 'STANDARD',
      contractProviderId: providerInfo!.providerId,
      priceSource: contract.template!.priceSource,
      contractTemplateId: contract.template!.id,
      contractTemplateName: '',
      serviceVariantId: contract.template!.serviceVariantId,
      serviceVariantName: contract.template!.serviceVariantName,
      duration: contract.duration.value,
      mileage: contract.mileage.value,
      startDateISOString: parsedStartDateISOString,
      value: value ? value.value : value,
      product: vehicleForRequest,
      paymentGateway: contract.paymentGateway,
      optionIds: contract.options.map((option) => option.id),
      amountPerPayment:
        contract.payments.customAmountPrPayment >= 0 // (!) NOTE: Important that zero is included!
          ? contract.payments.customAmountPrPayment
          : contract.payments.amountPrPayment.priceInclVat, // contract.payments.customAmountPrPayment,
      providerPayments: contract.payments.providerPayments,
      providerShare: contract.payments.providerShare.priceInclVat,
      customer: contract.customerId ? undefined : contract.customer,
      invoiceCustomer: contract.invoiceCustomerId ? undefined : contract.customer,
      customerId: contract.customerId,
      invoiceCustomerId: contract.invoiceCustomerId,
      reference: contract.reference,
      startMileage: contract.startMileage,
      startValue: contract.startValue,
      valueType: contract.valueType,
      startValueType: contract.startValueType,
      isDownpaymentDistributed: contract.payments.isDownpaymentDistributed,
      adjustedFrom: prettyIdentifier,
      isAdjustment: contract.flowType === 'ADJUST',
      contractProductType: null,
    }

    const priceSource: PriceSource = contract.template!.priceSource

    switch (priceSource) {
      case 'V4PricingTool':
        if (!contract.template?.v4ProductId) {
          throw new Error('PriceCalculation is missing v4ProductId in template: ' + !contract.template?.v4ProductId)
        }

        const vehicle: Vehicle = contract.product! as Vehicle
        const vehicleInfo: IVehicleInfo = vehicleToVehicleInfo(vehicle)

        const v4PTRequest: IStandardV4PricingToolContractCreationRequest = {
          ...(request as IStandardContractCreationRequest),
          v4ProductId: contract.template.v4ProductId,
          serviceVariantName: '',
          vehicleInfo: vehicleInfo,
        }
        return v4PTRequest
      default:
        return request
    }
  }

  private createProductPrintOfferRequest = () => {
    const { contract, freeContract, prettyIdentifier, providerInfo } = this.props
    const { duration, value, startValue, startValueType, valueType } = contract

    const vehicleForRequest: VehicleAlongItsContracts = { ...(contract.product! as VehicleAlongItsContracts) }
    vehicleForRequest.contracts = { contracts: [], durations: [], mileageDurationsMap: null }
    const parsedStartDateISOString = !contract.contractStartDateISO
      ? ''
      : new Date(contract.contractStartDateISO).toISOString() // NOTE: Important to call toISOString() on a created Date object.

    const request: IContractPrintCreationRequest = {
      type: freeContract ? 'CUSTOM' : 'STANDARD',
      contractProviderId: providerInfo!.providerId,
      priceSource: contract.template!.priceSource,
      contractTemplateName: '' + contract.template?.description,
      contractTemplateId: contract.template!.id,
      serviceVariantId: contract.template!.serviceVariantId,
      serviceVariantName: contract.template!.serviceVariantName,
      value: value ? value.value : value,
      valueType,
      duration: duration.value,
      startDateISOString: parsedStartDateISOString,
      product: vehicleForRequest,
      paymentGateway: contract.paymentGateway,
      optionIds: contract.options.map((option) => option.id),
      amountPerPayment:
        contract.payments.customAmountPrPayment > 0
          ? contract.payments.customAmountPrPayment
          : contract.payments.amountPrPayment.priceInclVat, // contract.payments.customAmountPrPayment,
      providerPayments: contract.payments.providerPayments,
      providerShare: contract.payments.providerShare.priceInclVat,
      customer: contract.customerId ? undefined : contract.customer,
      invoiceCustomer: contract.invoiceCustomerId ? undefined : contract.customer,
      customerId: contract.customerId,
      invoiceCustomerId: contract.invoiceCustomerId,
      reference: contract.reference,
      startMileage: contract.startMileage,
      startValue: startValue,
      startValueType: startValueType,
      isDownpaymentDistributed: contract.payments.isDownpaymentDistributed,
      adjustedFrom: prettyIdentifier,
      isAdjustment: contract.flowType === 'ADJUST',
      contractProductType: null,
    }
    return request
  }

  private createCreateOfferRequest = (newContractPaymentGateway?: PaymentGateway) => {
    const { contract, freeContract, providerInfo } = this.props
    const { value } = contract
    const isVehicle = 'vin' in contract.product

    const vehicleForRequest: VehicleAlongItsContracts | ProductAlongItsContracts = isVehicle
      ? { ...(contract.product! as VehicleAlongItsContracts) }
      : { ...(contract.product! as ProductAlongItsContracts) }
    vehicleForRequest.contracts = { contracts: [], durations: [], mileageDurationsMap: null }
    const parsedStartDateISOString = !contract.contractStartDateISO
      ? ''
      : new Date(contract.contractStartDateISO).toISOString() // NOTE: Important to call toISOString() on a created Date object.

    // contract.template?.

    if (freeContract) {
      const request: ICustomContractCreationRequest = {
        type: 'CUSTOM',
        contractProviderId: providerInfo!.providerId,
        priceSource: contract.template!.priceSource,
        contractProductType: contract.v4ProductType,
        contractTemplateName: '' + contract.template?.description,
        contractTemplateId: contract.template!.id,
        serviceVariantId: contract.template!.serviceVariantId,
        serviceVariantName: contract.template!.serviceVariantName,
        duration: contract.duration.value,
        mileage: contract.mileage.value,
        startDateISOString: parsedStartDateISOString,
        value: value ? value.value : value,
        valueType: contract.valueType,
        startValue: contract.startValue,
        startValueType: contract.startValueType,
        product: vehicleForRequest,
        paymentGateway: newContractPaymentGateway || contract.paymentGateway,
        optionIds: contract.options.map((option) => option.id),
        amountPerPayment: contract.payments.customAmountPrPayment,
        providerPayments: contract.payments.providerPayments,
        providerShare: contract.payments.providerShare.priceInclVat,
        // Only add the customer if customerId is not set
        customer: contract.customerId ? undefined : contract.customer,
        invoiceCustomer: contract.invoiceCustomerId ? undefined : contract.customer,
        customerId: contract.customerId,
        invoiceCustomerId: contract.invoiceCustomerId,
        reference: contract.reference,
        startMileage: contract.startMileage,
        isDownpaymentDistributed: contract.payments.isDownpaymentDistributed,
      }

      return request
    } else {
      const priceSource: PriceSource = contract.template!.priceSource
      const parsedStartDateISOString = !contract.contractStartDateISO
        ? ''
        : new Date(contract.contractStartDateISO).toISOString() // NOTE: Important to call toISOString() on a created Date object.

      const request: IStandardContractCreationRequest = {
        type: 'STANDARD',
        contractProviderId: providerInfo!.providerId,
        contractTemplateName: '' + contract.template?.description,
        contractTemplateId: contract?.template?.id || null,
        serviceVariantId: contract.template!.serviceVariantId,
        serviceVariantName: contract.template!.serviceVariantName,
        duration: contract.duration.value,
        mileage: contract.mileage.value,
        startDateISOString: parsedStartDateISOString,
        product: vehicleForRequest,
        paymentGateway: newContractPaymentGateway || contract.paymentGateway,
        optionIds: contract.options.map((option) => option.id),
        providerPayments: contract.payments.providerPayments,
        providerShare: contract.payments.providerShare.priceInclVat,
        customer: contract.customerId ? undefined : contract.customer,
        invoiceCustomer: contract.invoiceCustomerId ? undefined : contract.customer,
        customerId: contract.customerId,
        invoiceCustomerId: contract.invoiceCustomerId,
        reference: contract.reference,
        startMileage: contract.startMileage,
        startValue: contract.startValue,
        startValueType: contract.startValueType,
        value: value ? value.value : value,
        valueType: contract.valueType,
        isDownpaymentDistributed: contract.payments.isDownpaymentDistributed,
        priceSource: contract.template?.priceSource as PriceSource,
        contractProductType: contract.v4ProductType,
      }

      switch (priceSource) {
        case 'V4PricingTool':
        case 'Autoexperten':
          if (priceSource === 'V4PricingTool') {
            if (!contract.template?.v4ProductId) {
              throw new Error('PriceCalculation is missing v4ProductId in template: ' + !contract.template?.v4ProductId)
            }
          }

          const vehicle: Vehicle = contract.product! as Vehicle
          const vehicleInfo: IVehicleInfo = vehicleToVehicleInfo(vehicle)
          const parsedStartDateISOString = !contract.contractStartDateISO
            ? ''
            : new Date(contract.contractStartDateISO).toISOString() // NOTE: Important to call toISOString() on a created Date object.

          const v4PTRequest: IStandardV4PricingToolContractCreationRequest = {
            ...request,
            vehicleInfo: vehicleInfo,
            v4ProductId: contract.template?.v4ProductId || 0,
            startDateISOString: parsedStartDateISOString,
          }
          return v4PTRequest
        default:
          return request
      }
    }
  }

  private showConfirmSendContractOffer = async () => {
    this.setState({ showConfirmSendContractOffer: true })
  }

  private handleCloseShowConfirmSend = async () => {
    this.setState({ showConfirmSendContractOffer: false })
  }

  private handleSendContractOffer = async () => {
    const { contract, onFinalStep } = this.props
    const { flowType } = contract

    onFinalStep(true)
    this.setState({
      finalizationTransaction: true,
      sendDialog: true,
      showConfirmSendContractOffer: false,
    })

    if (flowType === 'CREATE') {
      trackEvent('Contract offer', 'Send offer')
      await this.sendCreateOffer()
    } else {
      trackEvent('Contract offer', 'Send adjustment offer')
      this.sendAdjustOffer()
    }
  }

  private sendAdjustOffer = async (isBuyNow: boolean = false): Promise<string> => {
    const { prettyIdentifier, contract } = this.props
    const request = this.createAdjustOfferRequest()
    const terms: IAdminCustomTermsRecord = { customTerms: contract.customTerms }

    let prettyIdentifierNew = ''
    if (prettyIdentifier) {
      const response = await postContractAdjust(request, prettyIdentifier, isBuyNow)
      if (response.data) {
        await postCustomTerms(response.data.serviceContract.prettyIdentifier, terms)
        prettyIdentifierNew = response.data.serviceContract.prettyIdentifier
        this.setState({
          finalizationTransaction: false,
          finalizationError: false,
          finalizationErrorMessage: '',
          prettyIdentifier: prettyIdentifierNew,
        })
      } else {
        const finalizationErrorMessage = (response.errorData && response.errorData.message) || ''
        this.setState({
          finalizationTransaction: false,
          finalizationError: true,
          finalizationErrorMessage,
          prettyIdentifier: '',
        })
      }
    }

    return prettyIdentifierNew || ''
  }

  private sendCreateOffer = async (
    isBuyNow: boolean = false,
    newContractPaymentGateway?: PaymentGateway,
  ): Promise<string> => {
    const { contract } = this.props
    const terms: IAdminCustomTermsRecord = { customTerms: contract.customTerms }
    const request = this.createCreateOfferRequest(newContractPaymentGateway)
    const response = await postContractOffer(request, isBuyNow)
    let prettyIdentifier: string = ''

    const serviceContractIdNum: number | undefined = response.data && response.data.serviceContractId
    if (response.data) {
      prettyIdentifier = response.data.prettyIdentifier

      await postCustomTerms(prettyIdentifier, terms)
      this.setState({
        finalizationTransaction: false,
        finalizationError: false,
        finalizationErrorMessage: '',
        prettyIdentifier,
        serviceContractIdNum,
      })
    } else {
      const finalizationErrorMessage = (response.errorData && response.errorData.message) || ''
      this.setState({
        finalizationTransaction: false,
        finalizationError: true,
        finalizationErrorMessage,
        prettyIdentifier,
        serviceContractIdNum,
        // we use this dialog for errors as well
        sendDialog: true,
        showConfirmSendContractOffer: false, // Close any Confirm dialog.
        showConfirmBuyNowV4: false, // Close any Confirm dialog.
        showConfirmBuyNowStripe: false, // Close any Confirm dialog.
      })
    }

    return prettyIdentifier
  }

  private handleDialogClose = async () => {
    // We need to get user_id for newly created user object, so we won't create it again
    if (!this.props.contract.customerId && this.props.contract.customer.email) {
      await this.setUserIdByEmail(this.props.contract.customer.email)
    }
    this.setState({
      isCreating: false,
      purchaseDialog: false,
      sendDialog: false,
      finalizationError: false,
      finalizationTransaction: false,
      prettyIdentifier: '',
    })
  }

  private setUserIdByEmail = async (email: string) => {
    // Check if the email address is already in use
    const response = await lookupUser(email)
    if (response.data) {
      const customer = response.data
      this.props.onCustomerChange(customer, true)
      this.props.onCustomerLockedChange(true)
    }
  }

  private handleToOffer = () => {
    const { onResetContract } = this.props
    const { finalizationError, prettyIdentifier } = this.state

    if (finalizationError) {
      this.setState({
        isCreating: false,
        sendDialog: false,
        purchaseDialog: false,
        finalizationError: false,
        finalizationErrorMessage: '',
        finalizationTransaction: false,
        prettyIdentifier: '',
      })
    } else {
      onResetContract()
      browserHistory.push(contractDetailsPath(prettyIdentifier))
    }
  }

  private handlePurchaseContract = async (contractPaymentGateway?: PaymentGateway) => {
    this.setState({ isCreating: true /*, isLoading: false*/ })

    const { contract } = this.props
    const { flowType } = contract

    this.handlePaymentGatewayChange(contractPaymentGateway || 'Stripe')

    let prettyIdentifier: string = ''
    if (flowType === 'CREATE') {
      trackEvent('Contract offer', 'Buy now')
      prettyIdentifier = await this.sendCreateOffer(true, contractPaymentGateway)
    } else {
      trackEvent('Contract offer', 'Send adjustment offer')
      prettyIdentifier = await this.sendAdjustOffer(true)
    }

    if (prettyIdentifier) {
      AllTermsCheckedOnContract.setAllChecked(prettyIdentifier)
      this.setState({ purchaseDialog: true, isCreating: false, isLoading: false })
    } else {
      this.setState({ activateContractFail: true, isCreating: false, isLoading: false })
    }
  }

  private handlePaymentGatewayChange = (paymentGateway: PaymentGateway) => {
    this.props.onChange({ paymentGateway })
    this.props.onContractUpdate()
  }

  private handleReferenceChange = (event: ChangeEvent<HTMLInputElement>) => {
    // We limit the length to 128 (simple way of limiting the input length)
    const reference = event.target.value.substr(0, 128)
    this.props.onChange({ reference })
    this.props.onContractUpdate()
  }

  private handleCustomTermsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const customTerms = event.target.value.substr(0, 380)
    this.props.onChange({ customTerms })
    this.props.onContractUpdate()
  }

  private handlePrintOffer = async (): Promise<string> => {
    trackEvent('Contract offer', 'Print')
    const { contract } = this.props
    const request =
      contract.contractObjectType === 'Vehicle' ? this.createPrintOfferRequest() : this.createProductPrintOfferRequest()

    const result = await postContractPrint(request)
    if (result.data) {
      const pdfUrl = pdfDownloadUrl(result.data.token)
      return pdfUrl
    } else {
      // @TODO display an error
      return 'Error'
    }
  }
}

export default withStyles(styles)(ContractFlowPagePayment)
